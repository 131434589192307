import React from 'react'
import CloudCard from './CloudCard'
import Choose from '../Remote/Choose'
import Footer from '../../HomeCom/Footer'

const CloudDeatail = () => {
  return (
    <section>
      <CloudCard/>
      <Choose/>
      
    </section>
  )
}

export default CloudDeatail
